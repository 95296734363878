import React from 'react';
import { Route } from 'react-router-dom';
import { verifyConfig } from './config';
import Callback from './pages/Callback';
import Logout from './pages/Logout';
import AccessToken from './pages/AccessToken';

const authRoutes = ({ fetchViewer }: { fetchViewer: () => Promise<unknown> }) => {
  verifyConfig();

  return [
    <Route key="/callback" path="/callback">
      <Callback fetchViewer={fetchViewer} />
    </Route>,
    <Route key="/logout" path="/logout" component={Logout} />,
    ...(process.env.NODE_ENV === 'development'
      ? [<Route key="/dev/access-token" path="/dev/access-token" component={AccessToken} />]
      : []),
  ];
};

export default authRoutes;
