import throwWithoutBreaking from '@motivo/guanyin/src/utils/throwWithoutBreaking';
import config from './config';

const customFetch = async (input: RequestInfo, body?: BodyInit) => {
  const response = await fetch(input, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body,
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  if (response.status === 204) {
    return '';
  }
  const clonedResponse = response.clone();

  try {
    return await response.json();
  } catch (e) {
    const responseText = await clonedResponse.text();
    throwWithoutBreaking(e, { extra: { responseText } });
  }
  return undefined;
};

export const exchangeCode = async (code: string, isAdmin: boolean) => {
  return customFetch(
    `${config.apiUrl}/auth/code`,
    JSON.stringify({
      code,
      origin: window.location.origin,
      isAdmin,
      ...(config.motivoProduct === 'astraea' && !isAdmin && { isMFE: true }),
    }),
  );
};

export const fetchAccessToken = async () => {
  return customFetch(
    `${config.apiUrl}/auth/accessToken`,
    JSON.stringify({
      origin: window.location.origin,
      ...(config.motivoProduct === 'zeus' && { isAdmin: true }),
      ...(config.motivoProduct === 'astraea' && { isMFE: true }),
    }),
  );
};

export const signIn = async ({
  userId,
  organizationAdminId,
  origin,
}: {
  userId?: number;
  organizationAdminId?: number;
  origin?: string;
}) => {
  const { authorizationUrl } = await customFetch(
    `${config.apiUrl}/auth/authorizationUrl`,
    JSON.stringify({
      origin: origin || window.location.origin,
      ...(config.motivoProduct === 'zeus' && { isAdmin: true }),
      ...(config.motivoProduct === 'astraea' && { isMFE: true }),
      ...(userId && { motivoUserId: userId }),
      ...(organizationAdminId && { organizationAdminId }),
    }),
  );

  window.location.href = authorizationUrl;
};

export const signOut = async ({
  userId,
  organizationAdminId,
}: {
  userId?: string;
  organizationAdminId?: string;
}) => {
  const { logoutUrl } = await customFetch(
    `${config.apiUrl}/auth/logoutUrl`,
    JSON.stringify({
      ...((config.motivoProduct === 'zeus' || userId || organizationAdminId) && { isAdmin: true }),
      ...(config.motivoProduct === 'astraea' && { isMFE: true }),
    }),
  );
  window.location.href = logoutUrl;
};
