export default async function fetchWithAuthHeaders(uri: RequestInfo, options: RequestInit) {
  const userId = localStorage.getItem('motivo_user_id');
  const organizationAdminId = localStorage.getItem('motivo_organization_admin_id');

  return fetch(uri, {
    credentials: 'include',
    ...options,
    headers: {
      ...options.headers,
      'X-Motivo-Referrer': window.location.toString().replace(window.location.search, ''),
      ...(userId && { 'x-motivo-userId': userId }),
      ...(organizationAdminId && { 'x-motivo-organizationAdminId': organizationAdminId }),
    },
  });
}
