import { useSuspenseQuery } from '@apollo/client';
import { ResultOf } from ':src/graphql';
import { viewerQuery } from '../queries';

export default function useViewer() {
  const {
    data: { viewer },
    refetch,
  } = useSuspenseQuery(viewerQuery);

  return { viewer, refetch, isLoggedIn: viewer.scope === 'user' };
}

export type Viewer = ResultOf<typeof viewerQuery>['viewer'];
