import { graphql } from ':src/graphql';

export const opportunitiesQuery = graphql(`
  query OpportunitiesQuery {
    opportunities(limit: 100, offset: 0) {
      result {
        id
        opportunityStatusId
        organization {
          id
          name
        }
        opportunitiesCandidates {
          id
          supervisorUserId
          opportunitiesCandidateStatusId
          declinedAt
          approvedAt
        }
        opportunitiesSupervisees {
          id
          supervisee {
            id
            fullName
            firstName
            lastName
            smallAvatar
          }
        }
        licenseCategory {
          id
          abbreviation
        }
        usState {
          id
          name
        }
        createdAt
      }
      total
    }
  }
`);

export const opportunityByIdQuery = graphql(`
  query OpportunityByIdQuery($id: String!) {
    opportunityById(id: $id) {
      id
      opportunityStatusId
      opportunitiesCandidates {
        id
        supervisorUserId
        opportunitiesCandidateStatusId
        text
        declinedAt
        approvedAt
        introRequestInviteSentAt
        introRequest {
          id
          introRequestStatusId
          scheduledAt
        }
        organizationMatches {
          id
          organizationId
        }
        tentativeMatch {
          id
          supervisorResponseId
          supervisorRespondedAt
        }
      }
      admin {
        id
        firstName
        lastName
      }
      opportunitiesSupervisees {
        id
        matchedAt
        superviseeUserId
        supervisee {
          id
          fullName
          firstName
          lastName
          smallAvatar
          actorId
          superviseeProfile {
            id
            licensureUsState {
              id
              name
            }
            licenseCategory {
              id
              abbreviation
            }
            isStudent
          }
        }
      }
      organization {
        id
        name
        description
        websiteUrl
        attachmentUrl
        requiresNotesInReports
        requiresSupervisorAdminHours
        introCallPreferenceId
        currentOrganizationRate {
          individualTotalPayoutAmountInCents
          dyadTotalPayoutAmountInCents
          groupTotalPayoutAmountInCents
        }
        billableWorkRecordCap {
          duration
          frequencyId
          billableWorkRecordCapTypeId
        }
        accountManagerAdmin {
          id
          firstName
          lastName
          fullName
        }
      }
      specialties {
        id
        name
      }
      populations {
        id
        name
      }
      sessionTypes {
        id
        displayName
      }
      supervisionStartBy
      supervisionAvailability
      organizationAdmin {
        id
        firstName
        lastName
        smallAvatar
        actorId
      }
    }
  }
`);
