import useViewer from ':src/domains/auth/hooks/useViewer';
import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

export default function PublicGatedRoute(props: RouteProps) {
  const history = useHistory();
  const { isLoggedIn } = useViewer();

  useEffectOnce(() => {
    if (isLoggedIn) {
      history.replace('/dashboard');
    }
  });

  return <Route {...props}></Route>;
}
