export function getAccessToken() {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken === 'null') {
    localStorage.removeItem('access_token');
    return null;
  }
  return accessToken;
}

export function setAccessToken(accessToken: string) {
  localStorage.setItem('access_token', accessToken);
}
