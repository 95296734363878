import { viewerQuery } from ':src/domains/auth/queries';
import { useLazyQuery } from '@apollo/client';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import { login } from '@motivo/styx/src/auth';
import React, { useEffect, useState } from 'react';
import { RouteProps, Route } from 'react-router';

const AuthWrapper = ({ children, path }: Pick<RouteProps, 'children' | 'path'>) => {
  const [loading, setLoading] = useState(true);

  const [getViewer] = useLazyQuery(viewerQuery, { fetchPolicy: 'network-only' });

  useEffect(() => {
    setLoading(true);

    const fetchViewer = async () => {
      const { data } = await getViewer();
      if (data?.viewer.scope === 'guest') {
        login();
        return;
      }
      setLoading(false);
    };

    fetchViewer();
  }, [path, getViewer]);

  if (loading) return <EmptyOrLoadingState loading />;

  return children as React.ReactNode;
};

export default function AuthenticatedRoute({ children, ...props }: RouteProps) {
  return (
    <Route {...props} key={props.path as string}>
      <AuthWrapper path={props.path}>{children}</AuthWrapper>
    </Route>
  );
}
