import useViewer from ':src/domains/auth/hooks/useViewer';
import React, { lazy } from 'react';
import { Route, RouteProps } from 'react-router-dom';

const Questionnaire = lazy(() => import(':src/domains/supervisee-onboarding/Questionnaire'));

export default function QuestionnaireGatedRoute({ children, ...restProps }: RouteProps) {
  const { isLoggedIn } = useViewer();

  return (
    <Route {...restProps}>
      <>
        {children}
        {!isLoggedIn && <Questionnaire />}
      </>
    </Route>
  );
}
